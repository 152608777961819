import React from 'react';
import CounterWrapper, {
  RenderedBlockWrapper,
} from './resourceslandingpagecounter.style.js';

const ResourcesLandingPageCounter = ({ page, setPage, totalPages }) => {
  const renderNumberBlocks = () => {
    const renderedBlock = [];
    const range = 2; // Number of page numbers to display on each side of the current page
    const ellipsis = <div>...</div>;

    // Add the first page
    renderedBlock.push(
      <div
        role="button"
        tabIndex={0}
        style={{
          backgroundColor: page === 1 ? '#03BAED' : 'white',
          color: page === 1 ? 'white' : 'gray',
        }}
        onClick={() => setPage(1)}
      >
        1
      </div>
    );

    // Add ellipsis if necessary
    if (page > range + 2) {
      renderedBlock.push(ellipsis);
    }

    // Add page numbers within the range
    for (
      let i = Math.max(page - range, 2);
      i <= Math.min(page + range, totalPages - 1);
      i++
    ) {
      renderedBlock.push(
        <div
          role="button"
          tabIndex={0}
          style={{
            backgroundColor: page === i ? '#03BAED' : 'white',
            color: page === i ? 'white' : 'gray',
          }}
          onClick={() => setPage(i)}
        >
          {i}
        </div>
      );
    }

    // Add ellipsis if necessary
    if (page < totalPages - range - 1) {
      renderedBlock.push(ellipsis);
    }

    // Add the last page
    if (totalPages > 1) {
      renderedBlock.push(
        <div
          role="button"
          tabIndex={0}
          style={{
            backgroundColor: page === totalPages ? '#03BAED' : 'white',
            color: page === totalPages ? 'white' : 'gray',
          }}
          onClick={() => setPage(totalPages)}
        >
          {totalPages}
        </div>
      );
    }

    return renderedBlock;
  };

  const handlePrevious = () => {
    setPage((page) => page - 1);
  };

  const handleNext = () => {
    setPage((page) => page + 1);
  };

  return (
    <CounterWrapper>
      <RenderedBlockWrapper>
        {page > 1 && <button onClick={handlePrevious}>Previous</button>}
        {renderNumberBlocks()}
        {page < totalPages && <button onClick={handleNext}>Next</button>}
      </RenderedBlockWrapper>
    </CounterWrapper>
  );
};

export default ResourcesLandingPageCounter;
